import axios from 'axios';
import DOMPurify from 'dompurify';


export const handleRAGQuery = async (query, conversationHistory, idToken) => {
  try {
    const response = await axios.post('https://api-989064782518.europe-west1.run.app/rag-query', {
      query,
      conversation_history: conversationHistory
    }, {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });

    return {
      response: response.data.answer,
      similarTexts: response.data.similar_texts
    };
  } catch (error) {
    console.error('Error in RAG query:', error);
    throw error;
  }
};

export const fetchGraphData = async (idToken) => {
  try {
    const response = await axios.get('https://api-989064782518.europe-west1.run.app/graph-data', {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching graph data:', error);
    throw error;
  }
};

export const performPCA = async (idToken) => {
  try {
    const response = await axios.post('https://api-989064782518.europe-west1.run.app/perform-pca', {}, {
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error performing PCA:', error);
    throw error;
  }
};

export const deleteNodes = async (nodeIds, idToken) => {
  try {
    const response = await fetch('https://api-989064782518.europe-west1.run.app/delete-nodes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({ nodeIds })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || 'Failed to delete nodes');
    }

    return await response.json();
  } catch (error) {
    console.error('Error in deleteNodes:', error);
    throw error;
  }
};

export const handleGraphGeneration = async (conversationHistory, idToken) => {
  try {
    // Create a temporary DOM element
    const tempDiv = document.createElement('div');
    
    // First sanitize with DOMPurify
    const purifiedContent = DOMPurify.sanitize(conversationHistory);
    tempDiv.innerHTML = purifiedContent;
    
    // Get only the text content, which automatically strips all HTML
    const cleanedContent = tempDiv.textContent || tempDiv.innerText || '';
    
    // Clean up any extra whitespace
    const finalContent = cleanedContent
      .replace(/\s+/g, ' ')
      .trim();
    
    console.log('cleanedContent:', finalContent);
    
    const response = await axios.post('https://api-989064782518.europe-west1.run.app/generate-graph', 
      { 
        conversation_history: finalContent,
        command: "generate"
      }, 
      { 
        headers: { 'Authorization': `Bearer ${idToken}` } 
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error in Graph generation:', error);
    throw error;
  }
};

export const saveEmbedding = async (text, source, windowId, idToken) => {
  try {
    const response = await axios.post('https://api-989064782518.europe-west1.run.app/save-embedding', { text, source, window_id: windowId }, { headers: { 'Authorization': `Bearer ${idToken}` } });
    return response.data;
  } catch (error) {
    console.error('Error in saveEmbedding:', error);
    throw error;
  }
};