import React, { useState, useEffect, useRef } from 'react';
import { auth } from '../firebase';
import { signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import Notes from './NotesChat/Notes';
import FinetuningResults from './Finetuning/FinetuningResults';
import NotesUtils from './NotesChat/NotesUtils';
import '../styles/home.css';
import NotesInterface from './NotesChat/NotesInterface';
import AutoScroller from './AutomatedBrainrot/AutomatedBrainrot';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
// import Notes from './Notes/Notes';
// import QuickNotesPlayground from './QuickNotesPlayground/QuickNotesPlayground';
import ScrollNotes from './ScrollNotes/ScrollNotes';

// Add this import at the top of the file with other imports
import birdnestLogo from '../birdnest_logo.png';
import { svg } from 'd3';
// import PaperPlane from './PaperPlane';
import Settings from './Settings/Settings';
import { faGear } from '@fortawesome/free-solid-svg-icons';

function Home() {
  const [user, setUser] = useState(null);
  const [showPlayground, setShowPlayground] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);
  const [showNotesChat, setShowNotesChat] = useState(false);
  const [hoveredLink, setHoveredLink] = useState(null);
  const linkRefs = {
    talk: useRef(null),
    chat: useRef(null),
    scroll: useRef(null),
    patreon: useRef(null),
    settings: useRef(null)
  };
  const [showSettings, setShowSettings] = useState(false);
  const [showScrollNotes, setShowScrollNotes] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      provider.setCustomParameters({
        prompt: 'select_account'
      });
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error during login:", error);
      if (error.code === 'auth/popup-blocked') {
        alert('Please enable popups for this site to login');
      } else if (error.code === 'auth/popup-closed-by-user') {
        console.log('Login popup was closed');
      } else {
        alert('An error occurred during login. Please try again.');
      }
    }
  };

  const handleGoToPlayground = () => {
    if (user) {
      setShowPlayground(true);
      setShowNotesChat(false);
      if (isMinimized) {
        setIsMinimized(false);
      }
    } else {
      handleLogin();
    }
  };

  const handleGoToNotesChat = () => {
    if (user) {
      setShowNotesChat(true);
      setShowPlayground(false);
      if (isMinimized) {
        setIsMinimized(false);
      }
    } else {
      handleLogin();
    }
  };

  const handleGoToScrollNotes = () => {
    if (user) {
      setShowScrollNotes(true);
      setShowPlayground(false);
      setShowNotesChat(false);
      if (isMinimized) {
        setIsMinimized(false);
      }
    } else {
      handleLogin();
    }
  };

  // const toggleFinetuningResults = () => {
  //   setShowFinetuningResults(!showFinetuningResults);
  // };

  // Get the commands from ChatUtils
  const { commands } = NotesUtils({ handleCommand: () => {} });

  const handleCloseNotesChat = () => {
    setShowNotesChat(false);
  };

  const handleMinimizeNotesChat = () => {
    setIsMinimized(!isMinimized);
  };

  const handleCloseNotesPlayground = () => {
    setShowPlayground(false);
  };

  const handleMinimizeNotesPlayground = () => {
    setIsMinimized(!isMinimized);
  };

  const handleCloseScrollNotes = () => {
    setShowScrollNotes(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setShowPlayground(false);
    } catch (error) {
      console.error("Error during logout:", error);
      alert('An error occurred during logout. Please try again.');
    }
  };

  const handleToggleSettings = () => {
    if (user) {
      setShowSettings(!showSettings);
    } else {
      handleLogin();
    }
  };

  return (
    <div className="home-container">
      {showNotesChat && user && (
        <div className={`notes-overlay ${isMinimized ? 'minimized' : ''}`}>
          <div className="notes-controls">
            <button className="control-button minimize" onClick={handleMinimizeNotesChat}>
              {isMinimized ? '□' : '−'}
            </button>
            <button className="control-button close" onClick={handleCloseNotesChat}>
              ×
            </button>
          </div>
          <Notes />
        </div>
      )}
      {/* {showPlayground && user && (
        <div className={`notes-overlay ${isMinimized ? 'minimized' : ''}`}>
          <div className="notes-controls">
            <button className="control-button minimize" onClick={handleMinimizeNotesPlayground}>
              {isMinimized ? '□' : '−'}
            </button>
            <button className="control-button close" onClick={handleCloseNotesPlayground}>
              ×
            </button>
          </div>
          <QuickNotesPlayground />
        </div>
      )} */}
      {showScrollNotes && user && (
        <div className={`notes-overlay ${isMinimized ? 'minimized' : ''}`}>
          <div className="notes-controls">
            <button className="control-button minimize" onClick={handleMinimizeNotesPlayground}>
              {isMinimized ? '□' : '−'}
            </button>
            <button className="control-button close" onClick={handleCloseScrollNotes}>
              ×
            </button>
          </div>
          <ScrollNotes />
        </div>
      )}
      <div className="landing">
        <div className="svg-container">
          <svg viewBox="0 0 300 300" xmlns="http://www.w3.org/2000/svg">
   
            <image 
              href={birdnestLogo} 
              x="50" 
              y="100" 
              width="200" 
              height="200"
              preserveAspectRatio="xMidYMid meet"
              style={{ userSelect: 'none' }}
            />
            <text 
              x="84" 
              y="290" 
              fontSize="11" 
              fontFamily="'Lucida Console', monospace"
              style={{ userSelect: 'none' }}
            >an IMABIRD PRODUCTION</text>
          </svg>
        </div>
        <div className="links-container">
          <div className="links-stack" style={{ textAlign: isMinimized ? 'left' : 'left' }}>
            {/* <div 
              className="link-wrapper"
              ref={linkRefs.talk}
              onMouseEnter={() => setHoveredLink('talk')}
              onMouseLeave={() => setHoveredLink(null)}
            >
              <a onClick={handleGoToPlayground} style={{ color: '#FF1493', cursor: 'pointer' }}>
                @Quick<div style={{ 
                  backgroundColor: 'yellow',
                  display: 'inline-block',
                  transform: 'rotate(-5deg)',
                  padding: '0 2px',
                  position: 'relative',
                  zIndex: -1,
                }}>
                  <span style={{
                    transform: 'rotate(10deg)', 
                    display: 'inline-block'
                  }}>__Note</span>
                </div>
              </a>
            </div> */}

            <div 
              className="link-wrapper"
              ref={linkRefs.chat}
              onMouseEnter={() => setHoveredLink('chat')}
              onMouseLeave={() => setHoveredLink(null)}
            >
              <a onClick={handleGoToNotesChat} style={{ color: '#FF1493', cursor: 'pointer' }}>
                <div style={{ 
                  backgroundColor: 'yellow',
                  display: 'inline-block',
                  transform: 'rotate(-5deg)',
                  padding: '0 2px',
                  position: 'relative',
                  zIndex: -1
                }}>
                  <span style={{
                    transform: 'rotate(0deg)', 
                    display: 'inline-block',
                  }}>Chat_</span>
                </div>
                withNotes
              </a>
            </div>

            <div 
              className="link-wrapper"
              ref={linkRefs.scroll}
              onMouseEnter={() => setHoveredLink('scroll')}
              onMouseLeave={() => setHoveredLink(null)}
            >
              <a onClick={handleGoToScrollNotes} style={{ color: '#FF1493', cursor: 'pointer' }}>
                <div style={{ 
                  backgroundColor: 'yellow',
                  display: 'inline-block',
                  transform: 'rotate(-5deg)',
                  padding: '0 2px',
                  position: 'relative',
                  zIndex: -1
                }}>
                  <span style={{
                    transform: 'rotate(0deg)', 
                    display: 'inline-block',
                  }}>write_</span>
                </div>
                Notes
              </a>
            </div>

            <div 
              className="link-wrapper"
              ref={linkRefs.patreon}
              onMouseEnter={() => setHoveredLink('patreon')}
              onMouseLeave={() => setHoveredLink(null)}
            >
              <a 
                href="https://patreon.com/user?u=88784207" 
                style={{ color: '#FF1493', cursor: 'pointer', fontSize: '8px' }} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                Patreon
              </a>
            </div>

            
{/* 
            <PaperPlane 
              targetRef={hoveredLink ? linkRefs[hoveredLink] : null}
              isHovered={!!hoveredLink}
            /> */}
          </div>
                
          {user && (
            <>
              <span className="separator" style={{ position: 'relative', top: '5px' }}>|</span>
              <a onClick={handleLogout} style={{ cursor: 'pointer' }}>Logout</a>
            </>
          )}
          
        </div>
      </div>
      {/* <hr /> */}
      
      {/* <section id="about">
        <h2>Artificial 
        <div style={{ 
                backgroundColor: 'yellow',
                display: 'inline-block',
                transform: 'rotate(1deg)',
                padding: '2px 0px 2px 20px',
                position: 'relative',
                zIndex: -1,
                fontSize: '12px'
              }}>
                un-
              </div>Intelligence
              {/* <AutoScroller /> */}
            {/* </h2> */}
  
      {/* </section>/} */}

      {/* {showSettings && user && (
        <div className="overlay">
          <Settings onClose={() => setShowSettings(false)} />
        </div>
      )} */}

    </div>
  );
}

export default Home;
