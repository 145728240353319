import React, { useState } from 'react';
import './NotesList.css';

const NotesList = ({ notes, isLoading, onNoteSelect, onNewNote, onDeleteNotes }) => {
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedNotes, setSelectedNotes] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');

  const filteredNotes = notes.filter(note => 
    note.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleNoteClick = (note, event) => {
    if (isSelectMode) {
      // In select mode, just toggle selection
      setSelectedNotes(prev => {
        const newSelected = new Set(prev);
        if (newSelected.has(note.id)) {
          newSelected.delete(note.id);
        } else {
          newSelected.add(note.id);
        }
        return newSelected;
      });
    } else {
      // In normal mode, open the note
      onNoteSelect(note);
    }
  };

  const handleDeleteSelected = () => {
    if (selectedNotes.size === 0) return;
    
    if (window.confirm(`Delete ${selectedNotes.size} selected note${selectedNotes.size > 1 ? 's' : ''}?`)) {
      onDeleteNotes(Array.from(selectedNotes));
      setSelectedNotes(new Set());
      setIsSelectMode(false);
    }
  };

  const toggleSelectMode = () => {
    setIsSelectMode(!isSelectMode);
    setSelectedNotes(new Set());
  };

  if (isLoading) {
    return <div className="notes-list-mobile">Loading...</div>;
  }

  return (
    <div className="notes-list-mobile">
      <div className="notes-list-header">
        <h1>Notes</h1>
        <div className="notes-list-actions">
          {isSelectMode ? (
            <>
              <button 
                onClick={handleDeleteSelected}
                className="delete-selected-button"
                disabled={selectedNotes.size === 0}
              >
                Delete ({selectedNotes.size})
              </button>
              <button 
                onClick={toggleSelectMode}
                className="cancel-selection-button"
              >
                Cancel
              </button>
            </>
          ) : (
            <>
              <button onClick={onNewNote} className="new-note-button">
                New Note
              </button>
              <button onClick={toggleSelectMode} className="select-mode-button">
                Select
              </button>
            </>
          )}
        </div>
      </div>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search notes..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="notes-list-content">
        {filteredNotes.length === 0 ? (
          <div className="no-notes">
            {searchTerm ? 'No matching notes found' : 'No notes yet'}
          </div>
        ) : (
          filteredNotes.map(note => (
            <div
              key={note.id}
              className={`note-item ${selectedNotes.has(note.id) ? 'selected' : ''}`}
              onClick={(e) => handleNoteClick(note, e)}
            >
              {isSelectMode && (
                <div className="note-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedNotes.has(note.id)}
                    onChange={() => {}} // React requires an onChange handler
                    onClick={(e) => e.stopPropagation()} // Prevent double-triggering
                  />
                </div>
              )}
              <div className="note-preview">
                {note.content.split('\n')[0] || 'Empty note'}
              </div>
              <div className="note-meta">
                <span className="note-timestamp">
                  {note.timestamp.toLocaleDateString()} {note.timestamp.toLocaleTimeString()}
                </span>
                <span className="note-word-count">
                  {note.content.split(/\s+/).filter(Boolean).length} words
                </span>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default NotesList; 