import { getFunctions, httpsCallable } from 'firebase/functions';
import { getApp } from 'firebase/app';

// Get Firebase Functions instance
const functions = getFunctions(getApp(), 'europe-west1'); // specify your region
const generateResponse = httpsCallable(functions, 'generate_response');

const ScrollNoteUtils = ({ handleCommand }) => {
  const handleQueryCommand = async (query, content, idToken, appendToContent) => {
    try {
      const response = await generateResponse({ 
        query: content,
        model: "gpt-4o-mini",
        temperature: 0.7
      });

      const { answer } = response.data;
      appendToContent('\n' + answer + '\n');
      return true;
    } catch (error) {
      console.error('Error processing query:', error);
      appendToContent('\nError: Failed to process query. Please try again.');
      return false;
    }
  };

  const commands = {
    help: {
      description: 'Show available commands',
      execute: (content, appendToContent) => {
        appendToContent(`
Available commands:
/q [query] - Ask a question
/save - Save the note
/close - Close the note
/delete - Delete the note
/help - Show this help message
        `);
      }
    },
    save: {
      description: 'Save the current note',
      execute: (content, appendToContent, handlers) => {
        if (handlers && handlers.onSave) {
          handlers.onSave();
          appendToContent('\nNote saved successfully!');
        } else {
          appendToContent('\nError: Save handler not found');
          console.error('Save handler not provided to ScrollNoteUtils');
        }
      }
    },
    close: {
      description: 'Close the current note',
      execute: (content, appendToContent, handlers) => {
        handlers.onClose();
      }
    },
    delete: {
      description: 'Delete the current note',
      execute: (content, appendToContent, handlers) => {
        handlers.onDelete();
      }
    }
  };

  const executeCommand = async (command, content, appendToContent, handlers = {}, idToken = null) => {
    const trimmedCommand = command.trim().toLowerCase();
    
    if (trimmedCommand.startsWith('/q ')) {
      const query = trimmedCommand.slice(3);
      return await handleQueryCommand(query, content, idToken, appendToContent);
    }

    const commandName = trimmedCommand.slice(1); // Remove the '/'
    const commandHandler = commands[commandName];

    if (commandHandler) {
      commandHandler.execute(content, appendToContent, handlers);
      return true;
    }

    appendToContent(`\nUnknown command: ${command}`);
    return false;
  };

  return {
    executeCommand,
    commands
  };
};

export default ScrollNoteUtils; 