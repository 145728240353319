import React, { useState, useRef, useEffect } from 'react';
import { auth } from '../../firebase';
import ScrollNoteUtils from './ScrollNoteUtils';
import { saveEmbedding } from '../../utils/apiHelpers';
import './NoteView.css';

const NoteView = ({ note, onClose, onUpdate, onDelete }) => {
  const [content, setContent] = useState(note.content);
  const [hasChanges, setHasChanges] = useState(false);
  const textareaRef = useRef(null);
  const { executeCommand } = ScrollNoteUtils({});

  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  useEffect(() => {
    setHasChanges(content !== note.content);
  }, [content, note.content]);

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const cursorPosition = textareaRef.current.selectionStart;
      const contentBeforeCursor = content.substring(0, cursorPosition);
      const contentAfterCursor = content.substring(cursorPosition);
      const currentLine = contentBeforeCursor.split('\n').pop().trim();

      if (currentLine.startsWith('/')) {
        const idToken = await auth.currentUser.getIdToken();
        await executeCommand(
          currentLine, 
          content, 
          appendToContent, 
          {
            onSave: handleSave,
            onClose,
            onDelete: handleDelete
          },
          idToken
        );
      } else {
        setContent(prevContent => {
          return contentBeforeCursor + '\n' + contentAfterCursor;
        });
        setTimeout(() => {
          textareaRef.current.selectionStart = textareaRef.current.selectionEnd = cursorPosition + 1;
        }, 0);
      }
    }
  };

  const appendToContent = (text) => {
    setContent(prev => prev + text);
  };

  const handleSave = async () => {
    try {
      onUpdate(note.id, content);
      setHasChanges(false);

      // Get the current user's ID token
      const idToken = await auth.currentUser.getIdToken();
      
      // Call the save-embedding API
      await saveEmbedding(
        content,
        'scroll-notes',
        note.id,
        idToken
      );
    } catch (error) {
      console.error('Error saving note and embedding:', error);
    }
  };

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      onDelete(note.id);
    }
  };

  const handleClose = async () => {
    if (hasChanges) {
      await handleSave();
    }
    onClose();
  };

  const handlers = {
    onSave: handleSave,
    onClose: handleClose,
    onDelete: () => onDelete(note.id)
  };

  return (
    <div className="note-view">
      <div className="note-view-header">
        <button onClick={handleClose} className="back-button">
          ← Back
        </button>
        <div className="note-actions">
          <button 
            onClick={handleSave} 
            className={`save-button ${hasChanges ? 'has-changes' : ''}`}
          >
            Save {hasChanges ? '*' : ''}
          </button>
          <button onClick={handleDelete} className="delete-button">
            Delete
          </button>
        </div>
      </div>
      <textarea
        ref={textareaRef}
        value={content}
        onChange={(e) => {
          setContent(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        className="note-content"
        placeholder="Start typing... (Use /help for commands)"
      />
    </div>
  );
};

export default NoteView; 