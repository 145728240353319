import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, limit, doc, setDoc, deleteDoc, writeBatch } from "firebase/firestore";
import { db, auth } from '../../firebase';
import NoteView from './NoteView';
import NotesList from './NotesList';
import './ScrollNotes.css';

const ScrollNotes = () => {
  const [notes, setNotes] = useState([]);
  const [activeNote, setActiveNote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (auth.currentUser) {
      loadUserNotes(auth.currentUser.uid);
    }
  }, []);

  const loadUserNotes = async (userId) => {
    try {
      setIsLoading(true);
      const q = query(collection(db, `users/${userId}/notes`), limit(30));
      const querySnapshot = await getDocs(q);
      const loadedNotes = querySnapshot.docs.map(doc => ({
        id: doc.id,
        content: doc.data().content,
        timestamp: doc.data().timestamp?.toDate() || new Date(),
      }));
      
      // Sort notes by timestamp, newest first
      loadedNotes.sort((a, b) => b.timestamp - a.timestamp);
      
      setNotes(loadedNotes);
    } catch (error) {
      console.error("Error loading notes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const createNewNote = async (content = '') => {
    const noteId = `${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
    const newNote = {
      id: noteId,
      content: content,
      timestamp: new Date()
    };
    
    setNotes(prev => [newNote, ...prev]);
    setActiveNote(newNote);

    try {
      await setDoc(doc(db, `users/${auth.currentUser.uid}/notes`, noteId), {
        content: content,
        timestamp: new Date()
      });
    } catch (error) {
      console.error('Error creating note:', error);
    }
  };

  const updateNote = async (id, content) => {
    try {
      console.log('Updating note:', id, content);
      await setDoc(doc(db, `users/${auth.currentUser.uid}/notes`, id), {
        content,
        timestamp: new Date()
      });
      console.log('Note updated in Firebase');

      setNotes(prev => prev.map(note => 
        note.id === id ? { ...note, content, timestamp: new Date() } : note
      ));
      console.log('Local state updated');
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const deleteNote = async (id) => {
    try {
      await deleteDoc(doc(db, `users/${auth.currentUser.uid}/notes`, id));
      setNotes(prev => prev.filter(note => note.id !== id));
      setActiveNote(null);
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  return (
    <div className="scroll-notes">
      {activeNote ? (
        <NoteView
          note={activeNote}
          onClose={() => setActiveNote(null)}
          onUpdate={updateNote}
          onDelete={deleteNote}
        />
      ) : (
        <NotesList
          notes={notes}
          isLoading={isLoading}
          onNoteSelect={setActiveNote}
          onNewNote={() => createNewNote()}
          onDeleteNotes={async (noteIds) => {
            try {
              const batch = writeBatch(db);
              noteIds.forEach(noteId => {
                const noteRef = doc(db, `users/${auth.currentUser.uid}/notes`, noteId);
                batch.delete(noteRef);
              });
              await batch.commit();
              setNotes(prevNotes => prevNotes.filter(note => !noteIds.includes(note.id)));
            } catch (error) {
              console.error('Error deleting notes:', error);
              alert('Failed to delete notes');
            }
          }}
        />
      )}
    </div>
  );
};

export default ScrollNotes; 